<template>
  <a-card :loading="loading" :bordered="false">
    <v-chart v-if="data.length !== 0" :forceFit="true" :height="height" :data="data" :scale="scale">
      <v-tooltip />
      <v-axis />
      <v-line position="statDay*orderMoney" />
      <v-point position="statDay*orderMoney" shape="circle" />
    </v-chart>
    <a-empty v-else></a-empty>
  </a-card>
</template>

<script>
import { statisticsOrderDay } from '@/api/statistics'

export default {
  data() {
    return {
      data: [],
      scale: [
        {
          dataKey: 'orderMoney',
          alias: '销售额',
          min: 0
        },
        {
          dataKey: 'statDay',
          min: 0,
          max: 1
        }
      ],
      loading: false,
      height: 400
    }
  },
  computed: {
    houseId() {
      return this.$store.state.house.houseId
    },
    userId() {
      return this.$store.state.user.info.id
    }
  },
  mounted() {
    this.reload()
  },
  methods: {
    reload() {
      this.loading = true
      const param = {
        developerId: this.userId,
        houseId: this.houseId
      }
      statisticsOrderDay(param).then(res => {
        console.log(res.data, 'res bar');
        this.data = res.data.map(item => {
          item.statDay = item.statDay.slice(5, 10)
          return item
        })
        console.log(this.data, 'data');
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>
