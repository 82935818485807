<template>
  <page-header-wrapper>
    <m-statictisc></m-statictisc>

    <a-row class="sale" :gutter="24" type="flex" :style="{ marginBottom: '24px' }">
      <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
        <a-card :bordered="false" title="销量" :style="{ height: '100%' }">
          <m-salebar></m-salebar>
        </a-card>
      </a-col>
      <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
        <a-card :bordered="false" title="销售业绩" :style="{ height: '100%' }">
          <m-salesbar></m-salesbar>
        </a-card>
      </a-col>
    </a-row>
    <a-card class="sum" :bordered="false" style="padding: 0;">
      <m-table></m-table>
    </a-card>
  </page-header-wrapper>
</template>
<script>
import MTable from './modules/MTable'
import MSalebar from './modules/MSalebar'
import MSalesbar from './modules/MSalesbar'
import MStatictisc from './modules/MStatictisc'
export default {
  name: 'Cockpit',
  components: {
    MTable,
    MSalebar,
    MSalesbar,
    MStatictisc
  },
  data() {
    return {
      allCount: 999
    }
  }
}
</script>

<style lang="less" scoped>
.sum /deep/ .ant-card-body {
  padding: 0;
}
.sale {
  /deep/ .ant-card-head-title {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
  }
}
</style>
