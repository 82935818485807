<template>
  <a-card :loading="loading">
    <a-table :columns="columns" :data-source="data" bordered :pagination="false">
      <template slot="name" slot-scope="text">
        <a>{{ text }}</a>
      </template>
    </a-table>
  </a-card>
</template>
<script>
import { statisticsReportOrderType } from '@/api/statistics'
const columns = [
  {
    title: '日期',
    dataIndex: 'key',
    align: 'center',
    scopedSlots: { customRender: 'key' }
  },
  {
    title: '报备总数',
    align: 'center',
    dataIndex: 'reportNum'
  },
  {
    title: '到访总数',
    align: 'center',
    dataIndex: 'visitNum'
  },
  {
    title: '认购套数',
    align: 'center',
    dataIndex: 'subNum'
  },
  {
    title: '签约套数',
    align: 'center',
    dataIndex: 'signNum'
  }
]
export default {
  data() {
    this.columns = columns
    return {
      loading: false,
      data: []
    }
  },
  computed: {
    houseId() {
      return this.$store.state.house.houseId
    },
    userId() {
      return this.$store.state.user.info.id
    }
  },
  mounted() {
    this.reload()
  },
  methods: {
    reload() {
      this.loading = true
      const param = {
        houseId: this.houseId,
        developerId: this.userId
      }
      const arr = ['昨日', '本周', '本月', '本季', '本年']
      statisticsReportOrderType(param).then(res => {
        this.loading = false
        this.data = res.data.map((item, index) => {
          item.key = arr[index]
          return item
        })
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style>
th.column-money,
td.column-money {
  text-align: right !important;
}
</style>
