<template>
  <a-card :bordered="false" :loading="loading" title="每日实时数据" class="card_bottom">
    <a-row type="flex" justify="start">
      <a-col :span="4">
        <info title="已接任务经纪人" :value="`${brokerCount}`" :bordered="true" />
      </a-col>
      <a-col :span="4">
        <info title="报备数" :value="`${reportCount}`" :bordered="true" />
      </a-col>
      <a-col :span="4">
        <info title="到访数" :value="`${visitCount}`" :bordered="true" />
      </a-col>
      <a-col :span="4">
        <info title="认购数" :value="`${subCount}`" :bordered="true" />
      </a-col>
      <a-col :span="4">
        <info title="签约数" :value="`${signCount}`" :bordered="true" />
      </a-col>
      <a-col :span="4">
        <info title="销售额" :value="`${signMoney} 元`" :bordered="true" />
      </a-col>
    </a-row>
  </a-card>
</template>
<script>
import { Info } from '@/components'
import { findStatisticsForToday } from '@/api/statistics'
export default {
  name: 'Cockpit',
  components: {
    Info
  },
  data() {
    return {
      brokerCount: 0,
      reportCount: 0,
      signCount: 0,
      subCount: 0,
      visitCount: 0,
      signMoney: 0,
      loading: false
    }
  },
  computed: {
    houseId() {
      return this.$store.state.house.houseId
    },
    userId() {
      return this.$store.state.user.info.id
    }
  },
  mounted() {
    this.reload()
  },
  methods: {
    reload() {
      this.loading = true
      const param = {
        developerId: this.userId,
        houseId: this.houseId
      }
      findStatisticsForToday(param).then(res => {
        const data = res.data
        this.brokerCount = data.brokerCount
        this.reportCount = data.reportCount
        this.signCount = data.signCount
        this.subCount = data.subCount
        this.visitCount = data.visitCount
        this.signMoney = data.signMoney
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.sum /deep/ .ant-card-body {
  padding: 0;
}
.card_bottom {
  .ant-col-4 {
    width: 16.6%;
  }
  /deep/ .ant-card-head-title {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
  }
}
</style>
